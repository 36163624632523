var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [_c("b-col", [_c("h1", [_vm._v("All tasks")])])],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c(
            "b-col",
            [
              _c(
                "label",
                { staticClass: "mr-4", attrs: { for: "text-search" } },
                [_vm._v("Search")]
              ),
              _c("b-form-input", {
                attrs: { id: "text-search" },
                on: {
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.setSearch($event)
                  }
                },
                model: {
                  value: _vm.filter.q,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "q", $$v)
                  },
                  expression: "filter.q"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c("label", { attrs: { for: "status-select" } }, [
                _vm._v("Status")
              ]),
              _c("c-select", {
                attrs: {
                  value: _vm.filter.Status,
                  id: "status-select",
                  options: _vm.statusOptions
                },
                on: {
                  input: function($event) {
                    _vm.$set(_vm.filter, "Status", $event.target.value)
                    _vm.setSearch()
                  }
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c("label", { attrs: { for: "owner-select" } }, [
                _vm._v("Project Manager")
              ]),
              _c("c-select", {
                attrs: {
                  value: _vm.filter.RootUserAccountID,
                  id: "owner-select",
                  options: _vm.taskUsers
                },
                on: {
                  input: function($event) {
                    _vm.$set(
                      _vm.filter,
                      "RootUserAccountID",
                      $event.target.value
                    )
                    _vm.setSearch()
                  }
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c("label", { attrs: { for: "owner-select" } }, [
                _vm._v("Assigned to")
              ]),
              _c("c-select", {
                attrs: {
                  value: _vm.filter.UserAccountID,
                  id: "owner-select",
                  options: _vm.taskUsers
                },
                on: {
                  input: function($event) {
                    _vm.$set(_vm.filter, "UserAccountID", $event.target.value)
                    _vm.setSearch()
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _vm.filterLoaded
            ? _c(
                "b-col",
                [
                  _c("rest-table", {
                    attrs: {
                      endpoint: "task-attributes",
                      filter: _vm.activeFilter,
                      fields: _vm.fields
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "cell(Status)",
                          fn: function(data) {
                            return [
                              _vm._v(" " + _vm._s(data.item.Status)),
                              data.item.ProgressDescription
                                ? [
                                    _c("br"),
                                    _c("em", [
                                      _vm._v(
                                        _vm._s(data.item.ProgressDescription)
                                      )
                                    ])
                                  ]
                                : _vm._e()
                            ]
                          }
                        },
                        {
                          key: "cell(Description)",
                          fn: function(data) {
                            return [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "task-detail-overview",
                                      params: { id: data.item.TaskAttributeID }
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(data.item.Description))]
                              )
                            ]
                          }
                        },
                        {
                          key: "cell(ParentTaskAttribute.Description)",
                          fn: function(data) {
                            return [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "task-detail-overview",
                                      params: {
                                        id: data.item.ParentTaskAttributeID
                                      }
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      data.item.ParentTaskAttribute.Description
                                    )
                                  )
                                ]
                              )
                            ]
                          }
                        },
                        {
                          key: "cell(actions)",
                          fn: function(data) {
                            return [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "task-detail-overview",
                                      params: { id: data.item.TaskAttributeID }
                                    }
                                  }
                                },
                                [_c("b-icon-pencil-square")],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "cell(SubProvider.Description)",
                          fn: function(data) {
                            return [
                              _vm._v(
                                _vm._s(
                                  data.item.SubProviderID == null
                                    ? "-"
                                    : data.item.SubProvider.Description
                                )
                              )
                            ]
                          }
                        },
                        {
                          key: "cell(CreationDateTime)",
                          fn: function(data) {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    data.item.CreationDateTime
                                  )
                                )
                              )
                            ]
                          }
                        },
                        {
                          key: "cell(CompletionDateTime)",
                          fn: function(data) {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    data.item.CompletionDateTime
                                  )
                                )
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3107695644
                    )
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }