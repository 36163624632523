<template lang="pug">
b-container(fluid)
  b-row(class='mb-4')
    b-col
      h1 All tasks

  b-row(class='mb-4')
    b-col
      label(for="text-search" class="mr-4") Search
      b-form-input(id="text-search" class="" v-model="filter.q" @keydown.enter="setSearch")
  
    b-col
      label(for="status-select") Status
      c-select(
        :value="filter.Status"
        @input="$set(filter, 'Status', $event.target.value); setSearch()"
        id="status-select"
        :options="statusOptions"
      )
  
    b-col
      label(for="owner-select") Project Manager
      c-select(
        :value="filter.RootUserAccountID"
        @input="$set(filter, 'RootUserAccountID', $event.target.value); setSearch()"
        id="owner-select"
        :options="taskUsers"
      )

    b-col
      label(for="owner-select") Assigned to
      c-select(
        :value="filter.UserAccountID"
        @input="$set(filter, 'UserAccountID', $event.target.value); setSearch()"
        id="owner-select"
        :options="taskUsers"
      )

  b-row(class='mt-3')
    b-col(v-if='filterLoaded')
      rest-table(endpoint='task-attributes' :filter='activeFilter' :fields='fields')
        template(v-slot:cell(Status)='data') 
          | {{ data.item.Status }}
          template(v-if="data.item.ProgressDescription")
            br
            em {{ data.item.ProgressDescription }}
        
        template(v-slot:cell(Description)='data')
          router-link(:to="{ name: 'task-detail-overview', params: { id: data.item.TaskAttributeID } }")
            | {{ data.item.Description }}

        template(v-slot:cell(ParentTaskAttribute.Description)='data')
          router-link(:to="{ name: 'task-detail-overview', params: { id: data.item.ParentTaskAttributeID } }")
            | {{ data.item.ParentTaskAttribute.Description }}

        template(v-slot:cell(actions)='data')
          router-link(:to="{ name: 'task-detail-overview', params: { id: data.item.TaskAttributeID } }")
            b-icon-pencil-square

        template(v-slot:cell(SubProvider.Description)='data') {{ data.item.SubProviderID == null ? '-' : data.item.SubProvider.Description }}
        template(v-slot:cell(CreationDateTime)='data') {{ data.item.CreationDateTime|dateFormat }}
        template(v-slot:cell(CompletionDateTime)='data') {{ data.item.CompletionDateTime|dateFormat }}
        

</template>

<script>
  export default {
    components: {
      RestTable: () => import('@/components/RestTable'),
    },
    props: {

    },
    data () {
      return {
        creating: false,
        filter: {
          Type: '',
          AttributeTypeID: 'FE65CC03-9820-42A6-81FE-EDC552681594',
          Status: 'Active',
          UserAccountID: '',
          RootUserAccountID: this.$root.$data.me.UserAccountID,
          q: '',
        },
        activeFilter: {
          Type: '',
          AttributeTypeID: 'FE65CC03-9820-42A6-81FE-EDC552681594',
          Status: 'Active',
          UserAccountID: '',
          RootUserAccountID: this.$root.$data.me.UserAccountID,
          q: '',
        },
        filterLoaded: false,
        projectTypes: [
          { code: 'AlbumProject', description: 'Album Project'},
          { code: 'AlbumSimpleProject', description: 'Simple Album Project'},
          { code: 'LicensingProject', description: 'Licensing Project'},
          { code: 'IncomingClaim', description: 'Incoming Claim'},
          { code: 'OutgoingClaim', description: 'Outgoing Claim'},
        ],
        statusOptions: [
          { value: 'All', label: 'All'},
          { value: 'Active', label: 'Active states'},
          { value: 'Inactive', label: 'Inactive states'},
          { value: 'Waiting', label: 'Waiting'},
          { value: 'Open', label: 'Open'},
          { value: 'In Progress', label: 'In Progress'},
          { value: 'On Hold', label: 'On Hold'},
          { value: 'Waiting for Manufacturing', label: 'Waiting for Manufacturing'},
          { value: 'Completed', label: 'Completed'},
          { value: 'Resolved', label: 'Resolved'},
        ],
        taskUsers: [],
        fields: [
          {
            key: 'TaskReference',
            label: 'Reference',
            sortable: true,
          },
          {
            key: 'CompletionDateTime',
            label: 'Due',
            sortable: true,
          },
          {
            key: 'Status',
            label: 'Status',
            sortable: true,
          },
          {
            key: 'User.FullName',
            label: 'Assigned to',
            sortable: true,
          },
          {
            key: 'ParentTaskAttribute.Description',
            label: 'Project',
            sortable: true,
          },
          {
            key: 'Description',
            label: 'Description',
            sortable: true,
          },
          {
            key: 'actions',
            label: 'Actions',
            class: 'link-icons',
          },
        ],
      }
    },
    watch: {
      activeFilter: {
        deep: true,
        handler () {

        },
      },
    },
    computed: {
      projectTypeOptions () {
        return [{value: '', label: 'All'}].concat(
          this.projectTypes.map(opt => { return { value: opt.code, label: opt.description } })
        )
      }
    },
    methods: {
      setSearch () {
        const filter = JSON.parse(JSON.stringify(this.filter))
        delete filter.ParentTaskAttributeID
        delete filter.AttributeTypeID

        localStorage.setItem('taskFilter', JSON.stringify(filter))

        Object.assign(this.activeFilter, this.filter)
      },
    },
    async mounted () {
      // Get users
      const response = await this.$http.get('task-users')
      const userList = response.data.map(user => { return { value: user.UserAccountID, label: user.FullName } })
      userList.unshift({
        label: 'All',
        value: ''
      })

      this.$set(
        this,
        'taskUsers', 
        userList
      )

      let filter = JSON.parse(localStorage.getItem('taskFilter'))
      if (!filter) {
        filter = {
          Status: 'Active',
          UserAccountID: this.$root.$data.me.UserAccountID,
          q: '',
        }
      }

      filter.AttributeTypeID = 'FE65CC03-9820-42A6-81FE-EDC552681594'
      Object.assign(this.filter, filter)
      this.setSearch()
      this.filterLoaded = true 
    }
  }
</script>

<style scoped>

</style>
